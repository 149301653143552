<template>
  <modify-apply-block
    :dataFileParm="{docName: '教育经历', isDoc: true}"
    data-type="worker_edu"
    ref="worker_edu_p"
    :getEdit="getEdit"
    :saveEdit="saveEdit"
    :addEdit="addEdit"
    :form-parms-add="formParmsAdd"
    block-name="教育经历"
    :is-absolut="true"
    :getTitle="getTitle"
    ref-name="worker_edu"
    :worker-id="workerId">
  </modify-apply-block>
</template>

<script>
import ModifyApplyBlock from './modifyApplyBlock'

import {
  workerEduRequest
} from '@/api'

export default {
  components: {
    ModifyApplyBlock
  },
  data () {
    return {
      workerId: null
    }
  },
  created () {
    this.workerId = this.$router.history.current.query.workerId
  },
  beforeRouteLeave (to, from, next) {
    if (this.$refs['worker_edu_p'].openForm) {
      this.$refs['worker_edu_p'].openForm = false
      next(false)
    } else {
      next()
    }
  },
  methods: {
    async getEdit () {
      if (this.workerId) {
        return await workerEduRequest.getEdit({
          workerId: this.workerId
        })
      }
    },
    async saveEdit (parm) {
      return await workerEduRequest.saveEdit(Object.assign({}, parm, {
        dataId: parm.id,
        id: undefined
      }))
    },
    async addEdit (parm) {
      return await workerEduRequest.addEdit(parm)
    },
    getTitle (data) {
      return data.edu || ''
    }
  },
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          label: '学历',
          selectDatas: this.$store.getters.selectDatas['worker_education_list'].map(v => ({value: v.key, label: v.label})),
          key: 'edu',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '学位',
          key: 'degree',
          columns: this.$store.getters.selectDatas['worker_edu_degree'].map(v => ({value: v.key, label: v.label}))
        },
        {
          type: 'input',
          label: '毕业院校',
          key: 'school'
        },
        {
          type: 'input',
          label: '学校编码',
          key: 'schoolCode'
        },
        {
          type: 'input',
          label: '专业',
          key: 'pro'
        },
        {
          type: 'datePicker',
          label: '入学时间',
          key: 'startDate'
        },
        {
          type: 'datePicker',
          label: '毕业时间',
          key: 'endDate'
        },
        {
          type: 'input',
          label: '学制',
          key: 'xz'
        },
        {
          type: 'input',
          label: '证书编号',
          key: 'zsCode'
        },
        {
          type: 'select',
          label: '教育形式',
          selectDatas: this.$store.getters.selectDatas['worker_edu_type_list'].map(v => ({value: v.key, label: v.label})),
          key: 'jyxs'
        },
        {
          type: 'select',
          label: '学历类型',
          selectDatas: [{key: '第一学历', label: '第一学历'}, {key: '最高学历', label: '最高学历'}, {key: '普通学历', label: '普通学历'}, {key: '最高学历兼第一学历', label: '最高学历兼第一学历'}],
          key: 'type'
        }]
        return data
      }
    }
  }
}
</script>
